
import {debounceTime} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { ValidationService } from '../../services/validation.service'
import {AbstractControl} from '@angular/forms';
import { NgForOf, NgIf } from "@angular/common";


@Component({
    selector: 'input-errors',
    templateUrl: './input.errors.html',
    imports: [
        NgIf,
        NgForOf
    ],
    standalone: true
})

export class InputErrors implements OnInit {

    @Input() delay: number = 100;
    @Input() control: AbstractControl;
    @Input() service: ValidationService;
    messages: string[] = [];

    ngOnInit() {
        if (this.delay) {
            this.control.statusChanges.pipe(debounceTime(this.delay)).subscribe(() => this.onChangeStatus());
        } else {
            this.control.statusChanges.subscribe(() => this.onChangeStatus());
        }
    }

    onChangeStatus() {
        let errors = this.control.errors;
        if (errors === null || !errors) {
            this.messages = [];
            return;
        }

        if (errors.filter && errors.max) {
            this.control.patchValue(this.control.value.substr(0, errors.required));
            return;
        }

        this.messages = [];
        for (let i in errors) {
            this.service.getTextMessage(i, errors[i]).forEach(_ => this.messages.push(_));
        }
    }
}